<template>
  <div class="flex gap-2 flex-col">
    <UiSelect v-model="model" :disabled="!!disabled">
      <UiSelectTrigger
        class="!bg-iq-black !border-iq-beige !text-iq-beige h-15"
      >
        <UiSelectValue :placeholder="t(`labels.languages.${model}`)" />
      </UiSelectTrigger>
      <UiSelectContent>
        <UiSelectGroup>
          <UiSelectItem
            v-for="(item, index) in languageInput.items"
            :key="index"
            :value="item"
          >
            <div class="flex gap-3 items-center">
              <Icon
                :name="`flag:${item === 'EN' ? 'gb' : item.toLowerCase()}-4x3`"
                class="w-10 h-9"
              />

              <div>{{ t(`labels.languages.${item}`) }}</div>
            </div>
          </UiSelectItem>
        </UiSelectGroup>
      </UiSelectContent>
    </UiSelect>
  </div>
</template>

<script setup lang="ts">
import { Language } from '~/enum/language.enum';
const { t } = useI18n({ useScope: 'local' });

defineProps<{
  disabled?: boolean;
}>();

const model = defineModel({
  required: true,
  type: String,
  default: () => ref(),
});

const languageInput = {
  fieldName: 'language',
  label: 'Sprache wählen',
  type: 'select',
  items: Object.values(Language),
};
</script>

<i18n lang="json">
{
  "de": {
    "labels": {
      "languages": {
        "DE": "Deutsch",
        "EN": "Englisch",
        "ES": "Spanisch",
        "FR": "Französisch",
        "NL": "Niederländisch"
      },
      "selectBox": "Sprache: "
    }
  },
  "en": {
    "labels": {
      "languages": {
        "DE": "German",
        "EN": "English",
        "ES": "Spanish",
        "FR": "French",
        "NL": "Dutch"
      },
      "selectBox": "Language: "
    }
  }
}
</i18n>
